import axios from 'axios';
import store from '../store';
import router from "@/routes";

export function Interceptors() {
    axios.interceptors.request.use(request => {
        // const accessToken = store.getters['AUTH_DATA'].accessToken || localStorage.getItem('accessToken');
        const accessToken = localStorage.getItem('accessToken');
        const userPermissions = store.getters['USER_PERMISSIONS'];

        if (!accessToken) {
            if (userPermissions) {
                console.log('axios.interceptors, LOGOUT');
                store.dispatch('LOGOUT').then(() => {
                    if (router.currentRoute.name !== 'logged-out') {
                        router.push({name: 'logged-out'});
                    }
                })
            }
        }

        if (accessToken) {
            request.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }
        return request;
    });

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry) {
                if (originalRequest.url !== "authentication_token") {
                    originalRequest._retry = true;
                    const authData = store.getters['AUTH_DATA'];

                    if (authData.accessToken && authData.refreshToken) {
                        store.dispatch('REFRESH_TOKEN', authData.refreshToken)
                            .then(() => {
                                const accessToken = store.getters['AUTH_DATA'].accessToken;
                                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                                // return axios(originalRequest);
                                location.reload(true);
                                return axios.request(originalRequest).then(data => {
                                    return data;
                                });
                            })
                            .catch(err => {
                                store.dispatch('LOGOUT').then(() => {
                                    console.log('LOGOUT');
                                    console.log(`interceptors.response refresh token, ${err}`);
                                })
                            });
                    } else {
                        // let isLogout = false;
                        console.log('inspectors 2 - LOGOUT');
                        store.dispatch('LOGOUT').then(() => {
                            // router.push({name: 'explore'});
                            // console.log(isLogout, 1);
                            // if (isLogout === false) {
                            //     console.log(isLogout, 2);
                            //     location.reload(true);
                            //     isLogout = true;
                            // }
                            //
                            // console.log('LOGOUT');
                            // // router.push({name: 'login'});
                        })
                    }
                }

            }
            return Promise.reject(error);
        })
}
