<template>
	<div class="without-footer-layout">
		<projectIsUnderConstructionWarning style="position: absolute; top: 60px; width: 100%; z-index: 1;"></projectIsUnderConstructionWarning>
		<slot></slot>
	</div>
</template>

<script>
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";

export default {
	name: "withoutFooterLayout",
	components: {
		projectIsUnderConstructionWarning
	}
}
</script>