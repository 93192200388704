import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';

// import 'material-icons/iconfont/material-icons.css';
import '@/assets/styles/main.scss';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import axios from 'axios';
import Axios from 'axios';
import router from './routes';
import store from './store';
import VueRx from 'vue-rx';

import {Interceptors} from "./utils/Interceptors";
import {toHumanFormat} from "@/utils/route.utils";
window.toHumanFormat = toHumanFormat;

import VueCompositionAPI from '@vue/composition-api';

import Vuelidate from 'vuelidate';
import initDirectives from './directives';
import TextareaAutosize from 'vue-textarea-autosize';
import VueSessionStorage from 'vue-sessionstorage';
import VueSplide from '@splidejs/vue-splide';

// import {initFacebookSdk} from "./utils/initFacebookSdk";

import VueEasyLightbox from 'vue-easy-lightbox';

import VuePwaInstallPlugin from "vue-pwa-install";
Vue.use(VuePwaInstallPlugin);

// clipper
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min';
import 'vuejs-clipper/dist/vuejs-clipper.css';
Vue.use(VuejsClipper);

// plugins
import './plugins';
// import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';

// Confetti
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);

// google-adsense
import Adsense from 'vue-google-adsense/dist/Adsense.min.js';

import createMultiTabState from 'vuex-multi-tab-state';

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$http = Axios;


// enable interceptors for http requests
Interceptors();
initDirectives();


Vue.use(VueRx);
Vue.use(require('vue-script2'));
Vue.use(Adsense);
Vue.use(TextareaAutosize);
Vue.use(Vuelidate);
Vue.use(VueSessionStorage);
Vue.use(VueSplide);
Vue.use(VueCompositionAPI);
Vue.use(VueEasyLightbox);



// pipes
Vue.filter('numbers', (value) => {
    let number = value + 1
    if (number < 10) {
        return "0" + number;
    }
    return number;
});

Vue.filter('minutes', (value) => {
    if (!value || typeof value !== "number") return "00:00"
    let min = parseInt(value / 60),
        sec = parseInt(value % 60)
    min = min < 10 ? "0" + min : min
    sec = sec < 10 ? "0" + sec : sec
    value = min + ":" + sec
    return value
});
Vue.directive('price-format', {
    bind: function (el, binding, vnode) {
        el.onblur = function () {
            let newValue = String(el.value);

            if (newValue.length !== 0) {
                if (newValue.indexOf(".") === -1) {
                    console.log(1);
                    // if there is no dot, we add it and two zeros
                    newValue = newValue + ".00";
                } else if (newValue.length - newValue.indexOf(".") - 1 < 2) {
                    console.log(2);
                    // if there are less than two digits after the period
                    newValue = newValue + "0";
                }
                vnode.context[binding.expression] = parseFloat(newValue).toFixed(2);
            }
        }
    }
});

// let isTabActive = true;
// window.addEventListener('focus', () => {
//     isTabActive = true;
//     console.log('tab is active');
// });
//
// window.addEventListener('blur', () => {
//     isTabActive = false;
//     console.log('tab is inactive');
// });


// set theme
const currentTheme = localStorage.getItem('theme');
if (!currentTheme) {
    document.body.classList.add('theme-dark');
    localStorage.setItem('theme', 'theme-dark');
} else {
    if (currentTheme === 'theme-dark') {
        document.body.classList.add('theme-dark');
    }
    if (currentTheme === 'theme-light') {
        document.body.classList.add('theme-light');
    }
}

// wait for facebook sdk to start app
// initFacebookSdk().then(startApp);

// function startApp() {
    new Vue({
        router,
        store,
        async beforeCreate() {
            if (this.$store.getters.PROFILE === null && this.$store.getters['AUTH_DATA'].accessToken) {
                await this.$store.dispatch('GET_PROFILE');
            }
        },
        vuetify,
        render: (h) => h(App),
    }).$mount('#app');
// }


