<template>
    <div class="search-layout">
        <projectIsUnderConstructionWarning style="position: absolute; top: 60px; width: 100%; z-index: 1;"></projectIsUnderConstructionWarning>
        <slot></slot>
    </div>
</template>

<script>
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";

export default {
    name: "searchLayout",
    components: {
        projectIsUnderConstructionWarning
    }
}
</script>

<style lang="scss">
.search-layout {
    height: 100%;
}

</style>