import axios from 'axios';

const initialState = () => ({
    activeReq: null,

    favoriteMusicRelease: [],
    countFavoriteMusicRelease: '',
    favoriteMusicReleaseCurrentPage: 1,
    favoriteMusicReleaseLoading: false,
});

export default {
    state: initialState(),

    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },

        SET_FAVOURITE_MUSIC_RELEASE: (state, data) => {
            state.favoriteMusicRelease = data.items;
            state.countFavoriteMusicRelease = data.count_results;
        },
        SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE: (state, data) => {
            state.favoriteMusicReleaseCurrentPage = data;
        },
        SET_FAVOURITE_MUSIC_RELEASE_LOADING: (state, data) => {
            state.favoriteMusicReleaseLoading = data;
        },
    },

    actions: {
        GET_FAVOURITE_MUSIC_RELEASE({commit, state}, params) {
            commit('SET_FAVOURITE_MUSIC_RELEASE_LOADING', true);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.get('favorite_releases', {signal, params: params})
                .then(response => {
                    commit('SET_FAVOURITE_MUSIC_RELEASE_LOADING', false);
                    commit('SET_FAVOURITE_MUSIC_RELEASE', response.data);
                })
                .catch(error => console.log(`GET_FAVOURITE_MUSIC_RELEASE ${error}`));
        },

        ADD_ALBUM_TO_FAVORITES({commit}, params) {
            let empty = {
                'hydra:member': [],
                'hydra:totalItems': ''
            }
            commit('SET_FAVOURITE_MUSIC_RELEASE', empty);

            let musicReleaseID = params.music_release_id;
            if (musicReleaseID) {
                return axios.post('add_favorite_song', {'song_id': "", 'music_release_id': musicReleaseID})
                    .then(() => {
                        for (let collection of this.getters['RELEASES_COLLECTION']) {
                            let getter = this.getters[collection];
                            if (getter && getter.length) {
                                let albumIndex = getter.findIndex((album) => +album.id === musicReleaseID);
                                if (albumIndex !== -1) {
                                    getter[albumIndex].isFavorite = true;
                                    if (getter[albumIndex].playList && getter[albumIndex].playList.length) {
                                        for (let i = 0; i < getter[albumIndex].playList.length; i++) {
                                            getter[albumIndex].playList[i].song.isFavorite = true;
                                        }
                                    }
                                }
                            }
                        }
                    })
                    .catch(err => {
                        console.error(`ADD_ALBUM_TO_FAVORITES - ${musicReleaseID}, ${err}`);
                    });

            } else {
                console.error('ADD_ALBUM_TO_FAVORITES, need music_release_id=', musicReleaseID);
            }
        },

        REMOVE_ALBUM_FROM_FAVORITES({commit}, params) {
            let musicReleaseID = params.music_release_id;

            if (musicReleaseID) {
                return axios.delete('remove_favorite_music_release', {data: {'song_id': "", 'music_release_id': musicReleaseID}})
                    .then(response => {
                        for (let collection of this.getters['RELEASES_COLLECTION']) {
                            let getter = this.getters[collection];
                            if (getter && getter.length) {
                                let albumIndex = getter.findIndex((album) => +album.id === musicReleaseID);
                                if (albumIndex !== -1) {
                                    getter[albumIndex].isFavorite = false;
                                    if (getter[albumIndex].playList && getter[albumIndex].playList.length) {
                                        for (let i = 0; i < getter[albumIndex].playList.length; i++) {
                                            getter[albumIndex].playList[i].song.isFavorite = false;
                                        }
                                    }
                                }
                            }
                        }
                    })
                    .catch(err => console.error(`REMOVE_ALBUM_FROM_FAVORITES ${err}`));

            } else {
                console.error('REMOVE_ALBUM_FROM_FAVORITES need music_release_id=', musicReleaseID);
            }

        },
    },

    getters: {
        FAVOURITE_MUSIC_RELEASE: state => state.favoriteMusicRelease,
        COUNT_FAVOURITE_MUSIC_RELEASE: state => state.countFavoriteMusicRelease,
        FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE: state => state.favoriteMusicReleaseCurrentPage,
        FAVOURITE_MUSIC_RELEASE_LOADING: state => state.favoriteMusicReleaseLoading,
    },
}
