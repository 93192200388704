<template>
    <div>
        <header :class="{ 'is-authenticated': AUTH_DATA.accessToken }">
            <div class="container" :class="{'mobileBg': isShowMobileMenu}">
                <div class="left_box">
                    <div class="logo" @click="goToHome"></div>
                </div>

                <div class="right_box">
                    <nav>
                        <ul class="right_nav">
	                        <li>
		                        <alertNotifications></alertNotifications>
	                        </li>
                            <!-- search -->
                            <li>
                                <router-link :to="{name: 'search'}" class="search" v-ripple></router-link>
                            </li>

                            <!-- Upload Music & Upload Event -->
                            <li class="upload_music_event">
                                <v-menu
                                        transition="slide-y-transition"
                                        :close-on-content-click="true"
                                        :width="125"
                                        offset-y
                                        nudge-left="40"
                                        nudge-bottom="10"
                                        origin="top center"
                                        content-class="yankees br20">
                                    <template v-slot:activator="{ on, attrs }">
                                        <button type="button" class="upload" v-bind="attrs" v-on="on" v-ripple @click="closeMainMenu">
                                            <span class="material-icons">add_circle</span>
                                        </button>
                                    </template>
                                    <v-list>
                                        <v-list-item v-ripple>
                                            <template>
	                                            <template v-if="isAdmin">
		                                            <router-link :to="{name: 'createMusicRelease', params: {create: true}}">Upload Music</router-link>
	                                            </template>
	                                            <template v-else>
		                                            <button type="button" @click="showComingSoonDialog = true">Upload Music</button>
	                                            </template>
                                            </template>
<!--                                            <template v-else>-->
<!--                                                <button type="button" @click="showComingSoonDialog = true">Upload Music</button>-->
<!--                                            </template>-->
                                        </v-list-item>
                                        <v-list-item v-ripple>
                                            <template v-if="isAdmin"><!-- TODO: temp blocking -->
                                                <router-link :to="{name: 'createEvent'}">Add an Event</router-link>
                                            </template>
                                            <template v-else>
                                                <button type="button" @click="showComingSoonDialog = true">Add an Event</button>
                                            </template>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </li>

<!--                             <li v-if="isAdmin" class="separate"></li> &lt;!&ndash; TODO: temp blocking &ndash;&gt;-->

                            <!-- Notifications -->
                             <li> <!-- TODO: temp blocking -->
                                 <notifications></notifications>
                             </li>

                            <!-- Messages -->
<!--                             <li v-if="isAdmin">&lt;!&ndash; TODO: temp blocking &ndash;&gt;-->
<!--                                <button type="button" class="messages" v-ripple></button>-->
<!--                             </li>-->

<!--                             <li v-if="isAdmin" class="separate"></li>&lt;!&ndash; TODO: temp blocking &ndash;&gt;-->

	                        <!-- Shopping Cart -->
<!--	                        <li v-if="isAdmin"> &lt;!&ndash; TODO: temp blocking &ndash;&gt;-->
		                        <shoppingCart v-if="AUTH_DATA.accessToken"></shoppingCart>
<!--	                        </li>-->

                            <li v-if="isAdmin">
                                <admin-menu></admin-menu>
                            </li>

                            <li v-if="AUTH_DATA.accessToken || AUTH_DATA.accessToken === ''">
                                <UserMenu v-if="AUTH_DATA.accessToken && !mobile"></UserMenu>

                                <UserMenuMobile v-if="AUTH_DATA.accessToken && mobile"></UserMenuMobile>

                                <router-link v-else-if="AUTH_DATA.accessToken === ''" class="login c2a_btn" :to="{name: 'login'}" v-ripple>
                                    Log In
                                    <div class="arrow">
                                        <span class="line_svg"></span>
                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                            <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                        </svg>
                                    </div>
                                </router-link>
                            </li>

                        </ul>
                    </nav>
                </div>

                <div class="mobile_input_search" v-if="mobileSearch">
                    <input type="text" v-model="searchText" v-on:keyup.enter="runMobileSearch()" ref="mobile_search">
                    <button type="button" class="close" v-ripple @click="closeMobileSearch"></button>
                </div>

            </div>

            <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>

        </header>
        <v-navigation-drawer
            v-if="mainMobileMenuDrawer"
                app
                fixed
                right
                temporary
                class=""
                v-model="mainMobileMenuDrawer"
                color="#141E35">
            <v-layout fill-height>
                <!--                <v-card-->
                <!--                    height=100%-->
                <!--                >-->
                <v-list-item>
                    <v-list-item-title>Item 1</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Item 2</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Item 3</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Item 4</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Item 5</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Item 6</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Item 7</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Item 8</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Item 8</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Item 8</v-list-item-title>
                </v-list-item>
                <!--                </v-card>-->
            </v-layout>

        </v-navigation-drawer>
    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import alertNotifications from '@/components/menu/alertNotifications';
import UserMenu from '@/components/menu/userMenu';
import UserMenuMobile from '@/components/menu/userMenuMobile';
import AdminMenu from "@/components/menu/adminMenu";
import shoppingCart from "@/components/menu/shoppingCart";
import notifications from "@/components/menu/notifications.vue";
import ComingSoonDialog from "@/components/dialogs/comingSoonDialog";
import isMobile from "@/mixins/isMobile";
import eventBus from "@/eventBus";

export default {
    name: 'mainHeader',
    components: {
	    alertNotifications,
        UserMenu,
        UserMenuMobile,
        AdminMenu,
        shoppingCart,
        notifications,
        ComingSoonDialog,
    },
    mixins: [isMobile],
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            showComingSoonDialog: false,
            searchText: '',
            searchActive: false,
            visibleExitBtn: false,
            mobileSearch: false,
            mainMobileMenuDrawer: false,
            showMainMenu: false,
            isShowMobileMenu: false
        };
    },
    watch: {
        '$route'(to, from) {
            this.visibleExitBtn = !!(to.name === 'search' || to.name === 'searchDetails' || (this.SEARCH_STRING.length));
        },
        SEARCH_STRING() {
            if (!this.SEARCH_STRING.length) {
                this.visibleExitBtn = false;
            }
        },
        isAdmin() {
            // console.log('watch header isAdmin', this.isAdmin);
        }
    },
    computed: {
        ...mapGetters([
			'PROFILE',
            'AUTH_DATA',
            'SEARCH_STRING',
            'isAdmin'
        ]),
    },


    mounted() {
        this.searchText = this.SEARCH_STRING;
        if (this.$router.history.current.name === 'search' || this.$router.history.current.name === 'searchDetails') {
            this.visibleExitBtn = true;
            // this.$refs.inputSearch.focus();
        } else if (this.$router.history.current.name === 'explore' && this.SEARCH_STRING.length) {
            this.visibleExitBtn = true;
        }

      eventBus.on('showMobileMenu', (isShow) => {
        this.isShowMobileMenu = isShow
      });
    },

    methods: {
        ...mapActions([
			'GET_PROFILE',
            'SEARCH_ARTISTS',
            'SEARCH_ALBUMS',
            'SEARCH_TRACKS',
            'CLEAN_SEARCH_RESULT',
        ]),
        closeMainMenu() {
            this.$store.commit('SET_HEADER_ADMIN_MENU', true);

            this.$store.commit('SET_HEADER_USER_MENU', false);
            this.$store.commit('SET_HEADER_NOTIFICATIONS', false);
            this.$store.commit('SET_HEADER_SHOPPING_CART', false);
        },
        runMobileSearch() {
            if (this.searchText.length >= 1) { // TODO: what is the min. number of characters
                this.$store.commit('UPDATE_SEARCH_STRING', this.searchText);
                this.CLEAN_SEARCH_RESULT();
                this.runSearch();
                this.$router.push({name: 'search'});
            }
        },
        closeMobileSearch() {
            this.mobileSearch = false;
            this.$store.commit('UPDATE_SEARCH_STRING', '');
        },
        goToHome() {
            this.searchActive = false;
            // if (this.$router.history.current.name !== 'explore') {
                this.$router.push("/explore").catch(err => console.error(`goToHome ${err}`));
            // }
			// search
            this.$store.commit('UPDATE_SEARCH_STRING', '');
            this.searchText = this.SEARCH_STRING;
            this.CLEAN_SEARCH_RESULT();

			// clear explore filter
	        this.$store.commit('SET_STATUS_FILTER', false);
	        this.$store.dispatch('CLEAN_FILTERS');
	        this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
	        this.$store.commit('SET_ALBUM_EXPANDED','');
			this.$store.commit('SET_GRID_ALBUM_SCROLL_ID', null);
			this.$store.commit('SET_TRACK_SCROLL_ID', null);
	        // this.$router.replace({path: `/`});
        },

        async runSearch() {
            this.$store.commit('UPDATE_SEARCH_LOADER', true);

            await this.SEARCH_ARTISTS({'name': this.searchText.trim()}).catch(err => console.error(`SEARCH_ARTISTS ${err}`));
            await this.SEARCH_ALBUMS({'title': this.searchText.trim(), 'order[releasedAt]': `DESC`}).catch(err => console.error(`SEARCH_ALBUMS ${err}`));
            await this.SEARCH_TRACKS({'title': this.searchText.trim(), 'order[releasedAt]': 'DESC'}).catch(err => console.error(`SEARCH_TRACKS ${err}`));

            this.$store.commit('UPDATE_SEARCH_LOADER', false);
        },
    },


};
</script>
<style lang="scss">
  .mobileBg {
    background: #090f1d !important;
  }
</style>
