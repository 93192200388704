<template>
    <div class="project_is_under_construction_warning" v-if="!isSetUnderstand">
        <v-container>
            <div class="message">
                This project is in <router-link :to="{name: 'faq'}" class="faq-link">alpha development stage.</router-link>
                Comments and other aspects of the site may be reset before the official launch.
            </div>
            <a href="#" class="understand" @click.prevent="understand">I understand</a>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "projectIsUnderConstructionWarning",

    data() {
        return {
            isSetUnderstand: true
        }
    },

    mounted() {
        // this.isSetUnderstand = false;
        // is user clicked on understand link
        if (localStorage.getItem('psytranc_understand') !== 'isSet') {
            this.isSetUnderstand = false;
        }
    },

    methods: {
        understand() {
            localStorage.setItem('psytranc_understand', 'isSet');
            this.isSetUnderstand = true;
        }
    }
}
</script>
